@each $label, $map in $themes {
    .#{$label} {
        @each $variant, $color in $map {
            .bg-#{$variant} {
                background-color: $color !important; // bootstrap override
            }

            .bg-light-#{$variant} {
                background-color: lighten($color, 30%);
            }
        }
    }
}

// shared grayscale style
.grayscale {
    filter: grayscale(1);
    opacity: 0.15;
}
