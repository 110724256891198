@each $label, $map in $themes {
    .#{$label} {
        .gradient-primary {
            $color: map-get($map, primary);

            background-image: linear-gradient(
                to right,
                $color,
                lighten($color, 20%)
            );
        }

        .gradient-secondary {
            $color: map-get($map, secondary);

            background-image: linear-gradient(
                to right,
                $color,
                lighten($color, 20%)
            );
        }
    }
}
