@use "sass:math";

$transition-duration: 0.3s;
$border-radius: 5px;

.button {
    transition: $transition-duration;
}

.animated {
    transition-duration: math.div($transition-duration, 2);
}

.question-box {
    min-height: 30rem;

    background: white;
    position: relative;
    display: flex;

    border-radius: $border-radius;
    overflow: hidden;
    @include material-shadow(2);

    header {
        background: rgba(0, 0, 0, 0.025);
        padding: 1.5rem;
        text-align: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .title-container {
        text-align: center;
        margin: 0 auto;
        padding: 1.5rem;
    }

    .quiz-form {
        display: block;
        white-space: normal;

        height: 100%;
        width: 100%;

        .quiz-form-container {
            height: 100%;
            margin: 15px 18px;

            .field-label {
                text-align: left;
                margin-bottom: 0.5rem;
            }
        }
    }

    .quiz-completed {
        width: 100%;
        padding: 6rem;
        text-align: center;

        > .icon {
            color: $danger;
            font-size: 5rem;

            .is-active {
                color: $success;
            }
        }
    }

    .question-container {
        height: 100%;
        width: 100%;

        .option-container {
            margin-top: 12px;
            flex-grow: 1;

            .option {
                padding: 9px 18px;
                margin: 0 18px;
                margin-bottom: 12px;
                transition: $transition-duration;
                cursor: pointer;
                background-color: rgba(0, 0, 0, 0.05);
                color: rgba(0, 0, 0, 0.85);
                border: transparent 1px solid;
                border-radius: $border-radius;

                &.is-selected {
                    border-color: rgba(black, 0.25);
                    background-color: #fff;
                    border-color: $blue;
                    font-weight: 600;
                    color: $blue;
                }

                &:hover {
                    background-color: rgba(0, 0, 0, 0.1);
                }

                &:active {
                    transform: scaleX(0.9);
                }
            }
        }

        .question-footer {
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            width: 100%;
            align-self: flex-end;

            .pagination {
                margin: 15px 25px;
                background-color: #fafafa;
            }
        }
    }
}

.paging {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
}

.button {
    padding: 0.5rem 1rem;
    border: 1px solid rgba(0, 0, 0, 0.25);
    margin: 0 0.25rem;

    transition: 0.3s;

    &:hover {
        cursor: pointer;
        background: #eceff1;
        border-color: rgba(0, 0, 0, 0.25);
    }

    &.is-active {
        background: $blue;
        color: white;
        border-color: transparent;

        &:hover {
            background: darken($blue, 10%);
        }
    }
}

@include media-breakpoint-down(md) {
    .question-box {
        align-items: center;
        justify-content: center;

        .question-container {
            display: flex;
            flex-direction: column;
        }
    }
}
