// Utility buttons for general use (delete, warning, gray)
$utility-colors: (
    "danger": $red,
    "warning": $yellow,
    "gray": $gray,
    "dimgray": $dimgray,
    
);

@each $key, $val in $utility-colors {
    .btn-#{$key} {
        @include generate-button($val);
    }
}

// Theme scoped buttons (primary, secondary)
@each $label, $map in $themes {
    .#{$label} {
        $primary: map-get($map, primary);
        .btn {
            font-size: larger;
        }
        .btn-#{$label} {
            @include generate-button($primary);
        }

        .btn-primary {
            @include generate-button($primary);
        }

        .btn-secondary {
            @include generate-button(map-get($map, secondary));
        }

        .btn-primary-outline,
        .btn-#{$label}-outline {
            color: $primary;
            background: transparent;
            border: 2px solid $primary;

            &:hover {
                background: transparent;
            }
        }
    }
}

// Quiz buttons
.bg-success {
    background-color: $green !important;
}

.bg-selected {
    background-color: $purple;
    color: $white;
    font-weight: bold;
    transition: background-color 500ms, color 500ms;
}

a.btn {
    @extend .font-raleway;
}
