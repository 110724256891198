#app {
    font-family: "Nunito", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $black;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    button {
        font-family: "Raleway", Arial, sans-serif;
    }

    h2 {
        font-weight: bold;
    }

    a {
        text-decoration: none;
    }

    input {
        font-family: "Nunito", sans-serif;
    }
}
