@each $label, $map in $themes {
    .#{$label} {
        @each $variant, $color in $map {
            .border-#{$variant} {
                border: 2px
                    solid
                    $color !important; // override important bootstrap rule
            }
        }
    }
}
