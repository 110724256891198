.sidebar-wrapper {
    width: 20rem;
    color: white;
    @include material-shadow($z-depth: 3, $strength: 2);

    #sidebar-heading {
        margin-top: 0.5rem;
        padding: 0.875rem 1.25rem;
        margin: 1rem;
        text-align: center;
        background: #ffffff40;

        #user-name {
            @extend .font-raleway;
            font-size: 1.5rem;
        }

        #profile-image {
            width: 6rem;
        }

        .btn.btn-link {
            font-size: 0.8rem;
            color: white;
            font-weight: 600;
        }
    }

    // Links
    .list-group {
        text-align: left;
    }

    .list-group-item-transparent {
        color: white;
        background: transparent;
        border: none;
        transition: all 0.5s ease-out;

        p {
            @extend .font-raleway;
            font-size: 1rem;
            font-weight: bold;
        }

        .icon-background {
            color: white;
        }
    }
}

.offcanvas {
    @extend .gradient-primary;
    color: white;
    height: 100vh;

    .offcanvas-body {
        #offcanvas-user-info {
            margin-bottom: 0.5rem;
            padding: 1rem;
            text-align: center;
            background: #ffffff40;

            #user-name {
                @extend .font-raleway;
                font-size: 1.5rem;
            }

            #family-name {
                font-size: 1rem;
            }

            #profile-image {
                width: 10rem;
            }

            .btn.btn-link {
                font-size: 1rem;
                color: white;
                font-weight: 600;
            }
        }
    }

    .btn-close {
        background: transparent
            url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
            center/1em auto no-repeat;
    }
}

// Theme scoped
@each $label, $map in $themes {
    .#{$label} {
        $bg: map-get($map, primary);

        .sidebar-wrapper {
            background: linear-gradient(-45deg, lighten($bg, 20%), $bg);

            .list-group-item-transparent {
                .icon-top {
                    color: $bg;
                }

                &:hover,
                &.router-link-active {
                    background-color: darken($bg, 10%);
                    color: white;

                    .icon-top {
                        color: darken($bg, 10%);
                    }
                }
            }
        }
    }
}
