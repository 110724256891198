.card {
    margin-bottom: 1.25rem;
    border: none;
    @include material-shadow(2);

    .card-footer {
        .btn {
            border-top-left-radius: 0rem;
            border-top-right-radius: 0rem;
        }
    }
    .table {
        margin-bottom: 0;
        > thead > tr > th {
            border-top: 0;
        }
    }
    &.card-transparent {
        border: 0;
        background-color: transparent;
        box-shadow: 0 0 0 #000;
        .card-header,
        .card-body {
            background-color: transparent;
            padding-left: 0;
            padding-right: 0;
        }
    }
    // reset default inherited card border
    > .list-group {
        border-top: 0;
        border-bottom: 0;
    }
}

// Locked overlay
.image-text-overlay {
    width: 100%;
    height: 15rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    transition: opacity 0.25s;

    .overlay-black {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        opacity: 0.7;
        background: #000;
    }

    .overlay-soft {
        opacity: 0.4;
    }

    .overlay-title {
        font-size: 4rem;
    }

    &:hover {
        transition: opacity 0.25s;
        opacity: 0.8;
    }
}

//shared text styles
.text-top-center,
.text-middle-center {
    display: table;
    p {
        display: table-cell;
        position: relative;
        z-index: 2;
        padding: 15px 20px 15px 20px;
    }
}

.text-top-center p {
    vertical-align: top;
    text-align: center;
}

.text-middle-center p {
    vertical-align: middle;
    text-align: center;
}

.card-text-courses {
    @include media-breakpoint-up(sm) {
        min-height: 15rem;
    }
    @include media-breakpoint-up(md) {
        min-height: 13rem;
    }
    @include media-breakpoint-up(lg) {
        min-height: 13rem;
    }
}
