@each $label, $map in $themes {
    .#{$label} {
        .text-primary {
            color: map-get(
                $map,
                primary
            ) !important; // Override !important bootstrap rule
        }

        .text-secondary {
            color: map-get(
                $map,
                secondary
            ) !important; // Override !important bootstrap rule
        }

        .text-#{$label} {
            color: map-get($map, primary);
        }
    }
}

.text-success {
    color: $green !important; // Override !important bootstrap rule
}

.text-blue {
    color: $blue;
}

.text-danger {
    color: $red !important;
}

.text-white {
    color: white;
}

.text-black {
    color: $black !important; // Override !important bootstrap rule
}

.font-weight-bold {
    font-weight: bold;
}

.text-emphasis {
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.12em;
}

.font-raleway {
    font-family: "Raleway", Arial, sans-serif;
}

.font-nunito {
    font-family: "Nunito", Arial, sans-serif;
}
