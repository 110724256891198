// Colors
$black: #4e4e4e;
$light-blue: #609dc6;
$blue: #406ab3;
$green: #5b9b38;
$purple: #7a60c4;
$red: #ca313f;
$yellow: #fec107;
$orange: #fd7e14;
$gray: #a8a8a8;
$dimgray: #696969;
$light-gray: #f1f1f1;

$soft-shadow: #83838315;
$background-white: #f9f9f9;

// Values
$border-radius-card: 0.35rem;
