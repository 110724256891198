@import '~@/assets/scss/main.scss';

h1 {
    max-width: 80%;
}

.greeting-message {
    font-size: 1.25rem;
    max-width: 50rem;
    min-height: 1.875rem;
}
