.k-window {
    @extend .font-raleway;
}

.k-grid {
    th {
        @extend .font-raleway;
    }

    td {
        @extend .font-nunito;
    }
}

.k-dropzone {
    background-color: white !important;
    .k-button {
        @extend .font-raleway;
    }

    .k-dropzone-hint {
        @extend .font-nunito;
    }
}
